import React from 'react';
import * as yup from 'yup';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';

import {
  Participant,
  CustomField,
  LegalDocument,
  UpdateParticipant,
  CustomPropertyType,
  UpdateCustomField,
  UpdateLegalDocument,
  InvitationToAppStatus
} from '@/domains';
import { translations } from '@/locale';
import { phoneNumberSchema } from '@/validation';
import { urls, rules, STRING_SHORT_MAX_LENGTH, ParticipantRole } from '@/constants';
import { getAgeFromBirthdate, mapStateToBoolean, mapBooleanToState, getDateFormat } from '@/util';
import {
  updateParticipant,
  deleteParticipant,
  updateParticipantState,
  updateParticipantTeams
} from '@/services/api/participant';

import If from '@/components/If';
import Card from '@/components/Card';
import Form from '@/components/Form';
import Team from '@/components/Team';
import Icon from '@/components/Icon';
import Modal from '@/components/Modal';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import HasRole from '@/components/HasRole';
import Picture from '@/components/Picture';
import TextInput from '@/components/TextInput';
import DateInput from '@/components/DateInput';
import BoxedIcon from '@/components/BoxedIcon';
import LiveSubmit from '@/components/LiveSubmit';
import Typography from '@/components/Typography';
import { SubmitError } from '@/components/Error';
import ToggleSwitch from '@/components/ToggleSwitch';
import TextWithIcon from '@/components/TextWithIcon';
import BoxedContent from '@/components/BoxedContent';
import { AppConsumer } from '@/components/Context/App';
import { withLabel } from '@/components/hoc/withLabel';
import ErrorBoundary from '@/components/ErrorBoundary';
import PictureUpload from '@/components/PictureUpload';
import { LanguageContext } from '@/components/Language';
import InputsContainer from '@/components/InputsContainer';
import GenderSelect from '@/components/Selects/GenderSelect';
import BooleanSelect from '@/components/Selects/BooleanSelect';
import CountrySelect from '@/components/Selects/CountrySelect';
import LanguageSelect from '@/components/Selects/LanguageSelect';
import { withValidation } from '@/components/hoc/withValidation';
import DataOptionSelect from '@/components/Selects/DataOptionSelect';
import ModuleSelectModal from '@/components/Modals/ModuleSelectModal';
import VitalStatusSelect from '@/components/Selects/VitalStatusSelect';
import EditTeamAccessModal from '@/components/Modals/EditTeamAccessModal';
import CrudConfirmationModal from '@/components/Modals/CrudConfirmationModal';
import InviteParticipantModal from '@/components/Modals/InviteParticipantModal';
import MergeParticipantsModal from '@/components/SearchableModals/MergeParticipantsModal';

import style from './Information.sass';
import CustomListTypeSelect from '@/components/Selects/CustomListTypeSelect';

const TextInputWithValidation = withValidation(TextInput);
const PictureUploadWithValidation = withValidation(PictureUpload);
const GenderSelectWithValidation = withValidation(GenderSelect);
const DateInputWithValidation = withValidation(DateInput);
const LanguageSelectWithValidation = withValidation(LanguageSelect);
const CountrySelectWithValidation = withValidation(CountrySelect);
const BooleanSelectWithLabel = withLabel(BooleanSelect);
const BooleanSelectWithValidation = withValidation(BooleanSelect);
const CustomListPropertySelectWithValidation = withValidation(CustomListTypeSelect);

const customPropertyTypeToComponentMap: Record<CustomPropertyType, any> = {
  [CustomPropertyType.Boolean]: BooleanSelectWithValidation,
  [CustomPropertyType.Number]: TextInputWithValidation,
  [CustomPropertyType.String]: TextInputWithValidation,
  [CustomPropertyType.List]: CustomListPropertySelectWithValidation
};

const mapCustomFields: (customFields: CustomField[]) => UpdateCustomField[] = (customFields) =>
  customFields
    ? customFields.map(({ customPropertyId: id, value, type }) => ({
        id,
        value: type === CustomPropertyType.Boolean ? (value ? value === 'true' : null) : value
      }))
    : [];

const mapLegalDocuments: (legalDocument: LegalDocument[]) => UpdateLegalDocument[] = (legalDocument) =>
  legalDocument ? legalDocument.map(({ isPresent: present, legalDocument: { id } }) => ({ id, present })) : [];

const mapParticipant: (participant: Participant) => UpdateParticipant = (participant) => {
  const {
    customFields,
    legalDocuments,
    state,
    homeStatus,
    legalStatus,
    customGeneralFields,
    createdAt,
    ...rest
  } = Object.entries(participant)
    .filter(([key, value]) => (typeof value === 'object' && !Array.isArray(value) ? !!value.value : true))
    .map(([key, value]) => (!!value.value ? [key, value.value] : [key, value]))
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}) as any;
  console.log('customGeneralFields', customGeneralFields);
  return {
    customProperties: [...mapCustomFields(customGeneralFields), ...mapCustomFields(customFields)],
    legalDocuments: mapLegalDocuments(legalDocuments),
    homeStatusId: !!homeStatus ? homeStatus.id : null,
    legalStatusId: !!legalStatus ? legalStatus.id : null,
    createdAt: getDateFormat(createdAt, 'yyyy-MM-dd', true),
    ...rest
  };
};

const renderRolePill = (participantRole: ParticipantRole) => {
  return (
    <div>
      <BoxedContent appearance="orange" className={style.accessLevelPill}>
        <Icon type="message" appearance="orange" className={style.pillIcon} />
        <Typography is="span" type="hummingbird" weight="bold" className={style.pillText}>
          <FormattedMessage id={translations.participantRoles[participantRole].name} />
        </Typography>
      </BoxedContent>
    </div>
  );
};

const renderTitle = (participant) => {
  return (
    <If
      condition={participant.birthDate}
      then={() => (
        <FormattedMessage
          id={translations.pages.teams.participants.details.information.profilePictureTitle}
          values={{
            name: participant.nickname,
            span: (content) => (
              <Typography key="years" is="span" type="sparrow" color="gray" weight="lighter">
                {content}
              </Typography>
            ),
            years: getAgeFromBirthdate(participant.birthDate)
          }}
        />
      )}
      else={() => participant.nickname}
    />
  );
};

const schema: yup.ObjectSchema<UpdateParticipant> = yup.object({
  firstName: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.firstName.label)
    .notRequired(),
  lastName: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.lastName.label)
    .notRequired(),
  nickname: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.nickname.label)
    .required(),
  address: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.address.label)
    .notRequired(),
  gender: yup
    .mixed()
    .label(translations.inputs.gender.label)
    .required(),
  phoneNumber: phoneNumberSchema.label(translations.inputs.phoneNumber.label).notRequired(),
  legalStatusId: yup
    .string()
    .nullable()
    .notRequired(),
  homeStatusId: yup
    .string()
    .nullable()
    .notRequired(),
  customProperties: yup.array().of(
    yup.object<UpdateCustomField>({
      id: yup
        .string()
        .nullable()
        .notRequired(),
      value: yup
        .string()
        .nullable()
        .notRequired()
    })
  ),
  legalDocuments: yup.array().of(
    yup.object<UpdateLegalDocument>({
      id: yup
        .string()
        .nullable()
        .notRequired(),
      present: yup
        .boolean()
        .nullable()
        .notRequired()
    })
  ),
  createdAt: yup
    .string()
    .label(translations.inputs.createdAt.label)
    .required()
});

interface Params {
  id: string;
}

interface Props {
  participant: Participant;
  onChange: () => any;
}

const Information: React.FC<RouteComponentProps<Params> & WrappedComponentProps & Props> = ({
  intl,
  match,
  history,
  participant,
  onChange
}) => {
  const { id } = match.params;
  const { language } = React.useContext(LanguageContext);

  return (
    <AppConsumer>
      {({ team, loading }) =>
        !loading && (
          <Card.Row className={style.root}>
            <Card.Column sm={12}>
              <ErrorBoundary>
                <If
                  condition={!participant}
                  then={() => 'Not found'}
                  else={() => (
                    <Form
                      id="participant-information"
                      schema={schema}
                      initialValues={mapParticipant(participant)}
                      subscription={{
                        submitting: true,
                        submitError: true,
                        dirty: true,
                        pristine: true
                      }}
                      onSubmit={(data) =>
                        updateParticipant(
                          id,
                          {
                            ...data,
                            createdAt: `${data.createdAt}T${getDateFormat(
                              participant.createdAt,
                              'HH:mm:ss.SSS',
                              true
                            )}Z`
                          },
                          team.id
                        ).then(onChange)
                      }
                    >
                      {({ form, submitting, dirty, pristine, submitError }, formId) => {
                        const customProperties = [
                          ...(!!participant.customGeneralFields ? participant.customGeneralFields : []),
                          ...(!!participant.customFields ? participant.customFields : [])
                        ];

                        return (
                          <React.Fragment>
                            <Spacer xs={1} />
                            <div className={style.profile}>
                              <Form.Field
                                is={PictureUploadWithValidation}
                                id={`${formId}-image-url`}
                                name="imageUrl"
                                title={renderTitle(participant)}
                                accept="image/x-png,image/jpeg"
                                readOnly={submitting}
                              />

                              <If
                                condition={participant.participantAccount.id != null}
                                then={() => (
                                  <div className={style.participantPhoto}>
                                    <Picture
                                      size="xl"
                                      type="participant"
                                      url={participant.imageUrl ? participant.imageUrl : null}
                                    />
                                    <div className={style.participantPhotoData}>
                                      <Typography is="span" type="swan" weight="bold">
                                        {renderTitle(participant)}
                                      </Typography>

                                      <div className={style.pillAndRole}>
                                        {renderRolePill(participant.participantAccount.roleLevel)}
                                        <Modal
                                          data={{
                                            roleLevel: participant.participantAccount.roleLevel,
                                            modulesAccess: participant.participantAccount.modulesAccess
                                          }}
                                          modal={ModuleSelectModal}
                                          teamId={team.id}
                                          participantId={participant.participantAccount.id}
                                          onSubmit={onChange}
                                        >
                                          {({ open }) => (
                                            <Button type="button" onClick={open} className={style.roleButton}>
                                              <Typography is="span" type="hummingbird" weight="bold" color="orange">
                                                <FormattedMessage
                                                  id={
                                                    translations.pages.teams.participants.details.information.editRoles
                                                  }
                                                />
                                              </Typography>
                                            </Button>
                                          )}
                                        </Modal>
                                      </div>

                                      {participant.participantAccount.status === InvitationToAppStatus.Pending && (
                                        <Typography is="span" type="hummingbird" color="gray">
                                          <FormattedMessage
                                            id={
                                              translations.pages.teams.participants.details.information
                                                .invitationPending
                                            }
                                          />
                                        </Typography>
                                      )}
                                    </div>
                                  </div>
                                )}
                              />

                              <div className={style.deleteAndActive}>
                                {!!participant.state && (
                                  <HasRole to={rules.PARTICIPANT_ACTIVATION}>
                                    <LiveSubmit
                                      value={{ state: mapStateToBoolean(participant.state.value) }}
                                      onChange={({ state }) =>
                                        updateParticipantState(participant.id, mapBooleanToState(state)).then(() =>
                                          history.push(urls.participants.list)
                                        )
                                      }
                                    >
                                      {({ value: { state }, set }) => (
                                        <ToggleSwitch
                                          checked={state}
                                          type="checkbox"
                                          onChange={(event) => set('state', event.target.checked)}
                                        />
                                      )}
                                    </LiveSubmit>
                                  </HasRole>
                                )}

                                <HasRole to={rules.PARTICIPANT_DELETE}>
                                  <Modal
                                    modal={CrudConfirmationModal}
                                    message={intl.formatMessage({
                                      id: translations.modals.confirmationModals.remove
                                    })}
                                    onConfirm={() =>
                                      deleteParticipant(participant.id).then(() => history.push(urls.participants.list))
                                    }
                                  >
                                    {({ open }) => (
                                      <Button className={style.deleteButton} type="button" onClick={open}>
                                        <BoxedIcon type="bin" appearance="red" />
                                      </Button>
                                    )}
                                  </Modal>
                                </HasRole>
                              </div>
                            </div>

                            {participant.participantAccount.id == null && (
                              <React.Fragment>
                                <Spacer xs={4} />

                                <div className={style.digitalCoachInvite}>
                                  <TextWithIcon iconColor="blue" icon="eye" is="span" type="halcyon" weight="bold">
                                    <FormattedMessage
                                      id={
                                        translations.pages.teams.participants.details.information.participantAppInvite
                                          .title
                                      }
                                    />
                                  </TextWithIcon>

                                  <Spacer xs={1} />

                                  <div className={style.row}>
                                    <Typography icon="eye" is="h6" type="hummingbird">
                                      <FormattedMessage
                                        id={
                                          translations.pages.teams.participants.details.information.participantAppInvite
                                            .subtitle
                                        }
                                        values={{ br: <br /> }}
                                      />
                                    </Typography>
                                    <Modal
                                      modal={InviteParticipantModal}
                                      participantId={participant.id}
                                      imageUrl={participant.imageUrl}
                                      name={participant.nickname}
                                      age={getAgeFromBirthdate(participant.birthDate)}
                                      onSubmit={onChange}
                                    >
                                      {({ open }) => (
                                        <Button fat appearance="orange" onClick={open} type="button">
                                          <FormattedMessage
                                            id={translations.pages.digitalCoach.tabs.participants.invite}
                                          />
                                        </Button>
                                      )}
                                    </Modal>
                                  </div>
                                </div>
                              </React.Fragment>
                            )}

                            <Spacer xs={4} />

                            {!!participant.teams && (
                              <React.Fragment>
                                <Typography is="span" type="sparrow" weight="bold">
                                  <FormattedMessage
                                    id={translations.pages.teams.participants.details.information.teams}
                                  />
                                </Typography>
                                &nbsp;
                                <HasRole to={rules.PARTICIPANT_SHARE}>
                                  <Modal
                                    modal={EditTeamAccessModal}
                                    initialTeams={participant.teams}
                                    submitDestination={({ selectedItems }) =>
                                      updateParticipantTeams(participant.id, selectedItems.map((id) => ({ id }))).then(
                                        onChange
                                      )
                                    }
                                  >
                                    {({ open }) => (
                                      <Typography
                                        is="span"
                                        type="sparrow"
                                        weight="bold"
                                        color="orange"
                                        onClick={open}
                                        className={style.editAccess}
                                      >
                                        <FormattedMessage
                                          id={translations.pages.teams.participants.details.information.editAccess}
                                        />
                                      </Typography>
                                    )}
                                  </Modal>
                                </HasRole>
                                <Spacer xs={2} />
                                <div className={style.teams}>
                                  {participant.teams.map((team) => (
                                    <Team
                                      key={team.id}
                                      logo={team.imageUrl}
                                      name={team.name}
                                      border
                                      className={style.team}
                                    />
                                  ))}
                                </div>
                                <Spacer xs={7} />
                              </React.Fragment>
                            )}

                            <Typography is="h6" type="swan" weight="bold">
                              <FormattedMessage
                                id={translations.pages.teams.participants.details.information.personalInformation}
                              />
                            </Typography>

                            <Spacer xs={3} />

                            <InputsContainer>
                              <Form.Field
                                is={TextInputWithValidation}
                                type="text"
                                id={`${formId}-nickname`}
                                name="nickname"
                                label={intl.formatMessage({ id: translations.inputs.nickname.label })}
                                placeholder={intl.formatMessage({
                                  id: translations.inputs.nickname.placeholder
                                })}
                                readOnly={submitting}
                              />

                              {!!participant.firstName && (
                                <Form.Field
                                  is={TextInputWithValidation}
                                  type="text"
                                  id={`${formId}-first-name`}
                                  name="firstName"
                                  label={intl.formatMessage({ id: translations.inputs.firstName.label })}
                                  placeholder={intl.formatMessage({
                                    id: translations.inputs.firstName.placeholder
                                  })}
                                  readOnly={submitting}
                                />
                              )}

                              {!!participant.lastName && (
                                <Form.Field
                                  is={TextInputWithValidation}
                                  type="text"
                                  id={`${formId}-last-name`}
                                  name="lastName"
                                  label={intl.formatMessage({ id: translations.inputs.lastName.label })}
                                  placeholder={intl.formatMessage({
                                    id: translations.inputs.lastName.placeholder
                                  })}
                                  readOnly={submitting}
                                />
                              )}

                              <Form.Field
                                is={GenderSelectWithValidation}
                                type="text"
                                id={`${formId}-gender`}
                                name="gender"
                                label={intl.formatMessage({ id: translations.inputs.gender.label })}
                                placeholder={intl.formatMessage({ id: translations.inputs.gender.placeholder })}
                                readOnly={submitting}
                              />

                              <Form.Field
                                is={DateInputWithValidation}
                                type="text"
                                id={`${formId}-birth-date`}
                                name="birthDate"
                                label={intl.formatMessage({ id: translations.inputs.birthDate.label })}
                                placeholder={intl.formatMessage({
                                  id: translations.inputs.birthDate.placeholder
                                })}
                                readOnly={submitting}
                              />

                              {!!participant.nationality && (
                                <Form.Field
                                  is={CountrySelectWithValidation}
                                  type="text"
                                  id={`${formId}-nationality`}
                                  name="nationality"
                                  label={intl.formatMessage({ id: translations.inputs.nationality.label })}
                                  placeholder={intl.formatMessage({
                                    id: translations.inputs.nationality.placeholder
                                  })}
                                  readOnly={submitting}
                                  searchable
                                />
                              )}

                              {!!participant.language && (
                                <Form.Field
                                  is={LanguageSelectWithValidation}
                                  type="text"
                                  id={`${formId}-language`}
                                  name="language"
                                  label={intl.formatMessage({ id: translations.inputs.language.label })}
                                  placeholder={intl.formatMessage({
                                    id: translations.inputs.language.placeholder
                                  })}
                                  readOnly={submitting}
                                  searchable
                                />
                              )}

                              <Form.Field
                                is={DateInputWithValidation}
                                type="text"
                                id={`${formId}-created-at`}
                                name="createdAt"
                                label={intl.formatMessage({ id: translations.inputs.createdAt.label })}
                                placeholder={intl.formatMessage({
                                  id: translations.inputs.createdAt.placeholder
                                })}
                                readOnly={submitting}
                              />

                              {customProperties.map((customField, index) => (
                                <Form.Field
                                  key={index}
                                  is={customPropertyTypeToComponentMap[customField.type]}
                                  items={
                                    customField.type === CustomPropertyType.List
                                      ? customField.options.map((item) => ({
                                          value: item.option,
                                          name: item.option
                                        }))
                                      : null
                                  }
                                  type={customField.type === CustomPropertyType.Number ? 'number' : 'text'}
                                  id={`${formId}-${customField.name.replace(/ /g, '-').toLowerCase()}`}
                                  name={`customProperties[${index}].value`}
                                  label={customField.name}
                                  placeholder={customField.name}
                                  readOnly={submitting}
                                />
                              ))}
                            </InputsContainer>

                            <Spacer xs={3} />

                            <Typography is="h6" type="halcyon" weight="lighter" color="gray">
                              <FormattedMessage
                                id={translations.pages.teams.participants.details.information.contactInformation}
                              />
                            </Typography>

                            <Spacer xs={2} />

                            <InputsContainer>
                              {participant.phoneNumber && (
                                <Form.Field
                                  is={TextInputWithValidation}
                                  type="text"
                                  id={`${formId}-phone-number`}
                                  name="phoneNumber"
                                  label={intl.formatMessage({ id: translations.inputs.phoneNumber.label })}
                                  placeholder={intl.formatMessage({
                                    id: translations.inputs.phoneNumber.placeholder
                                  })}
                                  readOnly={submitting}
                                />
                              )}

                              {!!participant.address && (
                                <Form.Field
                                  is={TextInputWithValidation}
                                  type="text"
                                  id={`${formId}-address`}
                                  name="address"
                                  label={intl.formatMessage({ id: translations.inputs.address.label })}
                                  placeholder={intl.formatMessage({
                                    id: translations.inputs.address.placeholder
                                  })}
                                  readOnly={submitting}
                                />
                              )}
                            </InputsContainer>

                            {participant.vitalStatus && (
                              <React.Fragment>
                                <Spacer xs={3} />

                                <Typography is="h6" type="halcyon" weight="lighter" color="gray">
                                  <FormattedMessage
                                    id={translations.pages.teams.participants.details.information.status}
                                  />
                                </Typography>

                                <Spacer xs={2} />

                                <InputsContainer>
                                  <Form.Field
                                    is={VitalStatusSelect}
                                    type="text"
                                    id={`${formId}-vital-status`}
                                    name="vitalStatus"
                                    label={intl.formatMessage({ id: translations.inputs.vitalStatus.label })}
                                    placeholder={intl.formatMessage({
                                      id: translations.inputs.vitalStatus.placeholder
                                    })}
                                    readOnly={submitting}
                                  />
                                </InputsContainer>
                              </React.Fragment>
                            )}

                            <Spacer xs={5} />

                            <If
                              condition={
                                !!participant.legalStatus || !!participant.homeStatus || !!participant.legalDocuments
                              }
                              then={() => (
                                <React.Fragment>
                                  <Typography is="h6" type="swan" weight="bold">
                                    <FormattedMessage
                                      id={translations.pages.teams.participants.details.information.legalInformation}
                                    />
                                  </Typography>

                                  <Spacer xs={3} />

                                  <InputsContainer>
                                    {!!participant.legalStatus && (
                                      <Form.Field
                                        is={DataOptionSelect.LegalStatusSelect}
                                        teamId={team.id}
                                        type="text"
                                        id={`${formId}-legal-status-id`}
                                        name="legalStatusId"
                                        label={intl.formatMessage({
                                          id: translations.inputs.legalStatus.label
                                        })}
                                        placeholder={intl.formatMessage({
                                          id: translations.inputs.legalStatus.placeholder
                                        })}
                                        readOnly={submitting}
                                        language={language}
                                      />
                                    )}

                                    {!!participant.homeStatus && (
                                      <Form.Field
                                        is={DataOptionSelect.HomeStatusSelect}
                                        teamId={team.id}
                                        type="text"
                                        id={`${formId}-home-status-id`}
                                        name="homeStatusId"
                                        label={intl.formatMessage({ id: translations.inputs.homeStatus.label })}
                                        placeholder={intl.formatMessage({
                                          id: translations.inputs.homeStatus.placeholder
                                        })}
                                        readOnly={submitting}
                                        language={language}
                                      />
                                    )}
                                  </InputsContainer>

                                  <Spacer xs={3} />

                                  <If
                                    condition={!!participant.legalDocuments}
                                    then={() => (
                                      <React.Fragment>
                                        <Typography is="h6" type="halcyon" weight="lighter" color="gray">
                                          <FormattedMessage
                                            id={
                                              translations.pages.teams.participants.details.information.legalDocuments
                                            }
                                          />
                                        </Typography>

                                        <Spacer xs={2} />

                                        <InputsContainer className={style.booleans}>
                                          {participant.legalDocuments.map((document, index) => (
                                            <Form.Field
                                              key={document.legalDocument.id}
                                              is={BooleanSelectWithLabel}
                                              type="text"
                                              id={`${formId}-${document.legalDocument.name
                                                .replace(/ /g, '-')
                                                .toLowerCase()}`}
                                              name={`legalDocuments[${index}].present`}
                                              label={
                                                document.legalDocument.translations &&
                                                document.legalDocument.translations[language.toLowerCase()]
                                                  ? document.legalDocument.translations[language.toLowerCase()]
                                                  : document.legalDocument.name
                                              }
                                              readOnly={submitting}
                                              inputClassName={style.booleanLegalDocument}
                                              labelClassName={style.booleanLabel}
                                            />
                                          ))}
                                        </InputsContainer>
                                      </React.Fragment>
                                    )}
                                  />
                                </React.Fragment>
                              )}
                            />

                            {!!submitError && <SubmitError error={submitError} />}

                            <Spacer xs={12} />

                            <div className={style.mergeAndSave}>
                              <HasRole to={rules.PARTICIPANT_MERGE}>
                                <Modal modal={MergeParticipantsModal} sourceParticipantId={participant.id}>
                                  {({ open }) => (
                                    <TextWithIcon
                                      is="span"
                                      type="sparrow"
                                      color="orange"
                                      className={style.merge}
                                      onClick={open}
                                      icon="edit"
                                      iconColor="orange"
                                      boxed
                                    >
                                      <FormattedMessage
                                        id={translations.pages.teams.participants.details.information.merge}
                                      />
                                    </TextWithIcon>
                                  )}
                                </Modal>
                              </HasRole>

                              <Modal
                                modal={CrudConfirmationModal}
                                message={intl.formatMessage({
                                  id: translations.modals.confirmationModals.edit
                                })}
                                onConfirm={() => form.submit()}
                              >
                                {({ open }) => (
                                  <Button
                                    form={formId}
                                    type="button"
                                    onClick={open}
                                    appearance="orange"
                                    className={style.saveButton}
                                    disabled={!dirty || pristine}
                                  >
                                    <FormattedMessage id={translations.pages.organization.generalInformation.save} />
                                  </Button>
                                )}
                              </Modal>
                            </div>
                          </React.Fragment>
                        );
                      }}
                    </Form>
                  )}
                />
              </ErrorBoundary>
            </Card.Column>
          </Card.Row>
        )
      }
    </AppConsumer>
  );
};

export default injectIntl(withRouter(Information));
